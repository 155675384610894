import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { Breakpoint, SxProps, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import { Logo } from '@mej/ui-next/src/components/logo/Logo';
import { HeaderSection } from '@mej/ui-next/src/layouts/core/HeaderSection';
import { LayoutSection } from '@mej/ui-next/src/layouts/core/LayoutSection';
import { Footer, HomeFooter } from '@mej/ui-next/src/layouts/main/footer';
import { Main } from '@mej/ui-next/src/layouts/main/main';
import { NavDesktop } from '@mej/ui-next/src/layouts/main/nav/desktop';
import type { NavMainProps } from '@mej/ui-next/src/layouts/main/nav/types';
import { usePathname } from '@mej/ui-next/src/routes/hooks/use-pathname';

export type MainLayoutProps = {
    sx?: SxProps<Theme>;
    children: React.ReactNode;
    header?: {
        sx?: SxProps<Theme>;
    };
    data: {
        nav: NavMainProps['data'];
    };
};

/**
 * MainLayout component that provides the main layout structure for the application.
 * It includes the header, footer, and main content sections.
 */
export function MainLayout({ sx, data, children, header }: MainLayoutProps) {
    const theme = useTheme();

    const pathname = usePathname();

    const homePage = pathname === '/';

    const layoutQuery: Breakpoint = 'md';

    const navData = data.nav;

    return (
        <LayoutSection
            headerSection={
                <HeaderSection
                    layoutQuery={layoutQuery}
                    sx={header?.sx}
                    slots={{
                        topArea: (
                            <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                                This is an info Alert.
                            </Alert>
                        ),
                        leftArea: <Logo />,
                        rightArea: (
                            <>
                                <NavDesktop
                                    data={navData}
                                    sx={{
                                        display: 'none',
                                        [theme.breakpoints.up(layoutQuery)]: {
                                            mr: 2.5,
                                            display: 'flex',
                                        },
                                    }}
                                />
                                <Box display="flex" alignItems="center" gap={{ xs: 1, sm: 1.5 }}>
                                    <Button variant="outlined" rel="noopener" href="/dashboard/">
                                        Logga in
                                    </Button>
                                </Box>
                            </>
                        ),
                    }}
                />
            }
            footerSection={homePage ? <HomeFooter /> : <Footer layoutQuery={layoutQuery} />}
            sx={sx}
        >
            <Main>{children}</Main>
        </LayoutSection>
    );
}
